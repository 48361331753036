.videoScrubber {
  cursor: pointer;
  white-space: nowrap;
}

.seek {
  display: inline;
  width: 0.8125rem;
  height: 0.8125rem;
}

@media (prefers-color-scheme: dark) {
  .seek {
    fill: white;
  }
}

@media (prefers-color-scheme: light) {
  .seek {
    fill: black;
  }
}
