@media screen, print {
  .contactMarginNoteHeading {
    margin-top: 0;
  }
}

@media (max-width: 760px) {
  .contactMarginNoteHeading {
    margin-top: revert;
  }

  .label {
    margin-top: 1rem;
    font-size: smaller;
  }
}
