@use 'mixins';
@use 'palette';

@import 'styles/paper.css';
@import 'styles/tufte.css';
@import 'styles/tufte-extended';

* {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// NOTE: A4 Height 1122.52px:
// NOTE: A4 Width 793.7px:

sup {
  font-size: 0.75rem;
}

// Uppercase
.uppercase {
  text-transform: uppercase;
}

// Override italics
.normal,
.subtitle.normal {
  font-style: normal;
}

// Text centering with offset for body left margin to looked centered
.centered {
  text-align: center;
  margin-left: palette.$offsetArticleLeftMargin;
}

.small,
span.small {
  font-size: 1.25rem;
}

//figure {
//  max-width: palette.$wideMainBodyWidth;
//}
//
//blockquote p,
//section > p,
//section > footer,
//section > table {
//  width: palette.$wideMainBodyWidth;
//}

// Have bullet and numbered point in empty left margin
ul,
ol,
dl,
section > ul,
section > ol,
section > dl {
  margin-left: palette.$offsetGapBetweenPointAndItemInList;
  padding-inline-start: palette.$putPointOutsideText;
  width: palette.$wideMainBodyWidth;
}

ul > li,
ol > li,
dl > div,
section > ul > li,
section > ol > li,
section > dl > div {
  padding-left: palette.$gapBetweenPointAndItemInList;
}

// Refine inline styling for margin note toggle symbol
p > span.marginnote-container > .margin-toggle > .toggle-symbol,
dl > div > span.marginnote-container > .margin-toggle > .toggle-symbol,
ol > li > span.marginnote-container > .margin-toggle > .toggle-symbol,
ul > li > span.marginnote-container > .margin-toggle > .toggle-symbol {
  margin-right: 0.5rem;
}

// Do not display margin to the right of a full width figure
// Show toggle symbol instead
// Refine spacing around toggle symbol and margin note
// Reduce margin-top of `figure` if there is toggle symbol
figure.fullwidth {
  @include mixins.marginNote;
}

// Set paper size for print in Chrome
@media print {
  @page {
    size: A4 portrait;
  }
}

// Print Preview
@media screen, print {
  html.print {
    font-size: 8px;

    body {
      width: 100%;
      padding: 0;
      max-width: 100%;
    }

    main > article > h1:first-of-type {
      margin-top: 0;
    }

    figure:not(.fullwidth) {
      max-width: palette.$printModeMainBodyWidth;
    }

    blockquote p,
    section > p,
    section > footer,
    section > table {
      width: palette.$printModeMainBodyWidth;
    }

    // Have bullet and numbered point in empty left margin
    ul,
    ol,
    dl,
    section > ul,
    section > ol,
    section > dl {
      margin-left: palette.$offsetGapBetweenPointAndItemInList;
      padding-inline-start: palette.$putPointOutsideText;
      width: palette.$printModeMainBodyWidth;
    }

    // TODO: hide side note and margin note toggles
  }
}
