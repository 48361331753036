@charset "UTF-8";

/* Import ET Book styles
   adapted from https://github.com/edwardtufte/et-book/blob/gh-pages/et-book.css */

@font-face {
  font-family: "et-book";
  src: url("../fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot");
  src: url("../fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot?#iefix") format("embedded-opentype"), url("../fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.woff") format("woff"), url("../fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.ttf") format("truetype"), url("../fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.svg#etbookromanosf") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "et-book";
  src: url("../fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot");
  src: url("../fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot?#iefix") format("embedded-opentype"), url("../fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.woff") format("woff"), url("../fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.ttf") format("truetype"), url("../fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.svg#etbookromanosf") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "et-book";
  src: url("../fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot");
  src: url("../fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot?#iefix") format("embedded-opentype"), url("../fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.woff") format("woff"), url("../fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.ttf") format("truetype"), url("../fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.svg#etbookromanosf") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "et-book-roman-old-style";
  src: url("../fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot");
  src: url("../fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot?#iefix") format("embedded-opentype"), url("../fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.woff") format("woff"), url("../fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.ttf") format("truetype"), url("../fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.svg#etbookromanosf") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Tufte CSS styles */
html {
  font-size: 15px;
}

body {
  width: 87.5%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12.5%;
  font-family: et-book, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  background-color: #fffff8;
  color: #111;
  max-width: 1400px;
  counter-reset: sidenote-counter;
}

/* Adds dark mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #151515;
    color: #ddd;
  }
}

h1 {
  font-weight: 400;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  font-size: 3.2rem;
  line-height: 1;
}

h2 {
  font-style: italic;
  font-weight: 400;
  margin-top: 2.1rem;
  margin-bottom: 1.4rem;
  font-size: 2.2rem;
  line-height: 1;
}

h3 {
  font-style: italic;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.4rem;
  line-height: 1;
}

hr {
  display: block;
  height: 1px;
  width: 55%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

p.subtitle {
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  display: block;
  line-height: 1;
}

.numeral {
  font-family: et-book-roman-old-style;
}

.danger {
  color: red;
}

@media (prefers-color-scheme: dark) {
  .danger {
    color: indianred;
  }
}

article {
  padding: 5rem 0rem;
}

section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

p,
dl,
ol,
ul {
  font-size: 1.3rem;
  line-height: 1.85rem;
}

p {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  padding-right: 0;
  vertical-align: baseline;
}

/* Chapter Epigraphs */
div.epigraph {
  margin: 5em 0;
}

div.epigraph > blockquote {
  margin-top: 3em;
  margin-bottom: 3em;
}

div.epigraph > blockquote,
div.epigraph > blockquote > p {
  font-style: italic;
}

div.epigraph > blockquote > footer {
  font-style: normal;
}

div.epigraph > blockquote > footer > cite {
  font-style: italic;
}

/* end chapter epigraphs styles */

blockquote {
  font-size: 1.4rem;
}

blockquote p {
  width: 55%;
  margin-right: 40px;
}

blockquote footer {
  width: 55%;
  font-size: 1.1rem;
  text-align: right;
}

section > p,
section > footer,
section > table {
  width: 55%;
}

/* 50 + 5 == 55, to be the same width as paragraph */
section > dl,
section > ol,
section > ul {
  width: 50%;
  -webkit-padding-start: 5%;
}

dt:not(:first-child),
li:not(:first-child) {
  margin-top: 0.25rem;
}

figure {
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  max-width: 55%;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0 0 3em 0;
}

figcaption {
  float: right;
  clear: right;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.1rem;
  line-height: 1.6;
  vertical-align: baseline;
  position: relative;
  max-width: 40%;
}

figure.fullwidth figcaption {
  margin-right: 24%;
}

/* Links: replicate underline that clears descenders */
a:link,
a:visited {
  color: inherit;
}

.no-tufte-underline:link {
  background: unset;
  text-shadow: unset;
}

a:link, .tufte-underline, .hover-tufte-underline:hover {
  text-decoration: none;
  background: -webkit-linear-gradient(#fffff8, #fffff8), -webkit-linear-gradient(#fffff8, #fffff8), -webkit-linear-gradient(currentColor, currentColor);
  background: linear-gradient(#fffff8, #fffff8), linear-gradient(#fffff8, #fffff8), linear-gradient(currentColor, currentColor);
  -webkit-background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  -moz-background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  background-repeat: no-repeat, no-repeat, repeat-x;
  text-shadow: 0.03em 0 #fffff8, -0.03em 0 #fffff8, 0 0.03em #fffff8, 0 -0.03em #fffff8, 0.06em 0 #fffff8, -0.06em 0 #fffff8, 0.09em 0 #fffff8, -0.09em 0 #fffff8, 0.12em 0 #fffff8, -0.12em 0 #fffff8, 0.15em 0 #fffff8, -0.15em 0 #fffff8;
  background-position: 0% 93%, 100% 93%, 0% 93%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  a:link, .tufte-underline, .hover-tufte-underline:hover {
    background-position-y: 87%, 87%, 87%;
  }
}

/* Adds dark mode */
@media (prefers-color-scheme: dark) {
  a:link, .tufte-underline, .hover-tufte-underline:hover {
    text-shadow: 0.03em 0 #151515, -0.03em 0 #151515, 0 0.03em #151515, 0 -0.03em #151515, 0.06em 0 #151515, -0.06em 0 #151515, 0.09em 0 #151515, -0.09em 0 #151515, 0.12em 0 #151515, -0.12em 0 #151515, 0.15em 0 #151515, -0.15em 0 #151515;
  }
}

a:link::selection,
a:link::-moz-selection {
  text-shadow: 0.03em 0 #b4d5fe, -0.03em 0 #b4d5fe, 0 0.03em #b4d5fe, 0 -0.03em #b4d5fe, 0.06em 0 #b4d5fe, -0.06em 0 #b4d5fe, 0.09em 0 #b4d5fe, -0.09em 0 #b4d5fe, 0.12em 0 #b4d5fe, -0.12em 0 #b4d5fe, 0.15em 0 #b4d5fe, -0.15em 0 #b4d5fe;
  background: #b4d5fe;
}

/* Sidenotes, margin notes, figures, captions */
img {
  max-width: 100%;
}

.sidenote,
.marginnote {
  float: right;
  clear: right;
  margin-right: -44%;
  width: 34%;
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 1.1rem;
  line-height: 1.3;
  vertical-align: baseline;
  position: relative;
}

.sidenote-number {
  counter-increment: sidenote-counter;
}

.sidenote-number:after,
.sidenote:before {
  font-family: et-book-roman-old-style;
  position: relative;
  vertical-align: baseline;
}

.sidenote-number:after {
  content: counter(sidenote-counter);
  font-size: 1rem;
  top: -0.5rem;
  left: 0.1rem;
}

.sidenote:before {
  content: counter(sidenote-counter) " ";
  font-size: 1rem;
  top: -0.5rem;
}

blockquote .sidenote,
blockquote .marginnote {
  margin-right: -82%;
  min-width: 59%;
  text-align: left;
}

div.fullwidth,
table.fullwidth {
  width: 100%;
}

div.table-wrapper {
  overflow-x: auto;
  font-family: "Trebuchet MS", "Gill Sans", "Gill Sans MT", sans-serif;
}

.sans {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  letter-spacing: .03em;
}

code, pre > code {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1.0rem;
  line-height: 1.42;
  -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS. See https://github.com/edwardtufte/tufte-css/issues/81#issuecomment-261953409 */
}

.sans > code {
  font-size: 1.2rem;
}

h1 > code,
h2 > code,
h3 > code {
  font-size: 0.80em;
}

.marginnote > code,
.sidenote > code {
  font-size: 1rem;
}

pre > code {
  font-size: 0.9rem;
  width: 52.5%;
  margin-left: 2.5%;
  overflow-x: auto;
  display: block;
}

pre.fullwidth > code {
  width: 90%;
}

.fullwidth {
  max-width: 90%;
  clear: both;
}

span.newthought {
  font-variant: small-caps;
  font-size: 1.2em;
}

input.margin-toggle {
  display: none;
}

label.sidenote-number {
  display: inline-block;
  max-height: 2rem; /* should be less than or equal to paragraph line-height */
}

label.margin-toggle:not(.sidenote-number) {
  display: none;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;
}
