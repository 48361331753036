.quilt {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas:
    'triggersOne triggersThree weatherOne weatherThree weatherFive weatherSeven'
    'triggersTwo triggersFour weatherTwo weatherFour weatherSix .';
  background-color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1),
    2px 2px 4px 2px rgba(0, 0, 0, 0.05);

  img {
    cursor: pointer;
  }

  .triggersOne {
    grid-area: triggersOne;
  }

  .triggersTwo {
    grid-area: triggersTwo;
  }

  .triggersThree {
    grid-area: triggersThree;
  }

  .triggersFour {
    grid-area: triggersFour;
  }

  .weatherOne {
    grid-area: weatherOne;
  }

  .weatherTwo {
    grid-area: weatherTwo;
  }

  .weatherThree {
    grid-area: weatherThree;
  }

  .weatherFour {
    grid-area: weatherFour;
  }

  .weatherFive {
    grid-area: weatherFive;
  }

  .weatherSix {
    grid-area: weatherSix;
  }

  .weatherSeven {
    grid-area: weatherSeven;
  }
}
