.marginNotes {
  font-style: normal;
}

@media (max-width: 760px) {
  .marginNotes {
    font-size: 0.75em;
    margin-bottom: 1rem;
  }
}
