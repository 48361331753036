@media screen, print {
  .label {
    display: none;
  }
}

@media (max-width: 760px) {
  .marginNote + .marginNote {
    padding-left: 1rem;
  }

  :global(.margin-toggle) {
    :global(.toggle-symbol):before {
      content: '⊕';
    }

    &:checked ~ :global(.margin-toggle) {
      :global(.toggle-symbol):before {
        content: '⊖';
      }

      :global(.toggle-symbol):before,
      .label {
        opacity: 0.5;
      }
    }

    .label {
      display: initial;
      padding-left: 0.375rem;
    }
  }
}
