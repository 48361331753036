@media (max-width: 760px) {
  .skillsMarginNoteHeading {
    margin-top: 2rem;
  }

  .label {
    display: inline-block;
    font-size: smaller;
  }
}
