@use 'theme';

$gapBetweenPointAndItemInList: theme.$halfRem;
$offsetGapBetweenPointAndItemInList: $gapBetweenPointAndItemInList * -1;
$putPointOutsideText: theme.$zeroRem;

$wideMainBodyWidth: theme.$fiftyFivePercent;
$narrowMainBodyWidth: theme.$hundredPercent;
$viewModeMainBodyWidth: theme.$fiftyFivePercent;
$printModeMainBodyWidth: theme.$seventyTwoPercent;
$articleLeftMargin: theme.$twelvePointFivePercent;
$offsetArticleLeftMargin: $articleLeftMargin * -1;
