@use '../palette';
@use '../mixins';

// Override `tufte.css` styling here

@media screen, print {
  html:not(.print) {
    :not(.marginnote) {
      h1,
      h2,
      h3,
      ul,
      ol,
      dl,
      p.subtitle {
        width: palette.$viewModeMainBodyWidth;
      }
    }

    p:not(.marginnote) {
      h1,
      h2,
      h3,
      ul:not(.fullwidth),
      ol:not(.fullwidth),
      dl {
        width: 100%;
      }
    }
  }

  html.print {
    :not(.marginnote) {
      h1,
      h2,
      h3,
      ul,
      ol,
      dl,
      & section ul,
      & section ol,
      & section dl,
      p.subtitle {
        width: palette.$printModeMainBodyWidth;
      }
    }

    p:not(.marginnote) {
      h1,
      h2,
      h3,
      ul,
      ol,
      dl {
        width: 100%;
      }
    }
  }
}

@media (max-width: 760px) {
  html:not(.print) {
    body {
      width: 84%;
      padding-left: 10%;
      padding-right: 6%;
    }

    hr,
    section > p,
    section > footer,
    section > table {
      width: 100%;
    }

    pre > code {
      width: 97%;
    }

    :not(.marginnote) {
      h1,
      h2,
      h3,
      ul,
      ol,
      dl,
      & section ul,
      & section ol,
      & section dl,
      p.subtitle {
        width: palette.$narrowMainBodyWidth;
      }
    }

    figure {
      // Use same styling for full width figure for consistency
      @include mixins.marginNote;
      max-width: 90%;
    }

    figcaption,
    figure.fullwidth figcaption {
      margin-right: 0%;
      max-width: none;
    }

    blockquote {
      margin-left: 1.5em;
      margin-right: 0em;
    }

    blockquote p,
    blockquote footer {
      width: 100%;
    }

    label.margin-toggle:not(.sidenote-number) {
      display: inline;
    }

    .sidenote,
    .marginnote {
      display: none;
    }

    .margin-toggle:checked ~ .sidenote,
    .margin-toggle:checked ~ .marginnote {
      display: block;
      float: left;
      left: 1rem;
      clear: both;
      width: 100%;
      box-sizing: border-box;
      padding-right: 4rem;
      margin: 1rem 0;
      vertical-align: baseline;
      position: relative;
    }

    label {
      cursor: pointer;
    }

    div.table-wrapper,
    table {
      width: 85%;
    }

    img {
      width: 100%;
    }
  }
}
