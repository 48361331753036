/** Adapted from cognitom's paper-css **/
/* REF: https://github.com/cognitom/paper-css */

/* Measurements */
:root {
  --0mm:          0mm;
  --5mm:          5mm;
  --10mm:         10mm;
  --15mm:         15mm;
  --16mm:         16mm;
  --17mm:         17mm;
  --18mm:         18mm;
  --20mm:         20mm;
  --25mm:         25mm;
  --A5-short:     148mm;
  --A5-long:      210mm;
  --A4-short:     210mm;
  --A4-long:      297mm;
  --A3-short:     297mm;
  --A3-long:      419mm;
  --letter-short: 8.5in;  /* 215.9mm */
  --letter-long:  11in;   /* 279.4mm */
  --legal-short:  8.5in;  /* 215.9mm */
  --legal-long:   14in;   /* 355.6mm */
}

/* REF: https://developer.mozilla.org/en-US/docs/Web/CSS/@page */
@page { margin: 0 }
body  { margin: 0 }
main  { margin: 0 }
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
main.A3               .sheet { width: var(--A3-short); height: var(--A3-long) }
main.A3.landscape     .sheet { width: var(--A3-long); height: var(--A3-short) }
main.A4               .sheet { width: var(--A4-short); height: var(--A4-long) }
main.A4.landscape     .sheet { width: var(--A4-long); height: var(--A4-short) }
main.A5               .sheet { width: var(--A5-short); height: var(--A5-long) }
main.A5.landscape     .sheet { width: var(--A5-long); height: var(--A5-short) }
main.Letter           .sheet { width: var(--letter-short); height: var(--letter-long) }
main.Letter.landscape .sheet { width: var(--letter-long); height: var(--letter-short) }
main.Legal            .sheet { width: var(--legal-short); height: var(--legal-long) }
main.Legal.landscape  .sheet { width: var(--legal-long); height: var(--legal-short) }

/** Printer margins **/
.sheet.printer-m-0mm { padding: var(--0mm) }
.sheet.printer-m-10mm { padding: var(--5mm) }
.sheet.printer-m-10mm { padding: var(--10mm) }
.sheet.printer-m-15mm { padding: var(--15mm) }
.sheet.printer-m-18mm { padding: var(--18mm) }
.sheet.printer-m-20mm { padding: var(--20mm) }
.sheet.printer-m-25mm { padding: var(--25mm) }

.sheet.printer-mx-0mm  { padding-left: var(--0mm); padding-right: var(--0mm); }
.sheet.printer-mx-5mm  { padding-left: var(--5mm); padding-right: var(--5mm); }
.sheet.printer-mx-10mm { padding-left: var(--10mm); padding-right: var(--10mm); }
.sheet.printer-mx-15mm { padding-left: var(--15mm); padding-right: var(--15mm); }
.sheet.printer-mx-18mm { padding-left: var(--18mm); padding-right: var(--18mm); }
.sheet.printer-mx-20mm { padding-left: var(--20mm); padding-right: var(--20mm); }
.sheet.printer-mx-25mm { padding-left: var(--25mm); padding-right: var(--25mm); }

.sheet.printer-my-0mm  { padding-top: var(--0mm); padding-bottom: var(--0mm); }
.sheet.printer-my-5mm  { padding-top: var(--5mm); padding-bottom: var(--5mm); }
.sheet.printer-my-10mm { padding-top: var(--10mm); padding-bottom: var(--10mm); }
.sheet.printer-my-15mm { padding-top: var(--15mm); padding-bottom: var(--15mm); }
.sheet.printer-my-18mm { padding-top: var(--18mm); padding-bottom: var(--18mm); }
.sheet.printer-my-20mm { padding-top: var(--20mm); padding-bottom: var(--20mm); }
.sheet.printer-my-25mm { padding-top: var(--25mm); padding-bottom: var(--25mm); }

.sheet.printer-mt-0mm  { padding-top: var(--0mm); }
.sheet.printer-mt-5mm  { padding-top: var(--5mm); }
.sheet.printer-mt-10mm { padding-top: var(--10mm); }
.sheet.printer-mt-15mm { padding-top: var(--15mm); }
.sheet.printer-mt-16mm { padding-top: var(--16mm); }
.sheet.printer-mt-17mm { padding-top: var(--17mm); }
.sheet.printer-mt-18mm { padding-top: var(--18mm); }
.sheet.printer-mt-20mm { padding-top: var(--20mm); }
.sheet.printer-mt-25mm { padding-top: var(--25mm); }

.sheet.printer-mb-0mm  { padding-bottom: var(--0mm); }
.sheet.printer-mb-5mm  { padding-bottom: var(--5mm); }
.sheet.printer-mb-10mm { padding-bottom: var(--10mm); }
.sheet.printer-mb-15mm { padding-bottom: var(--15mm); }
.sheet.printer-mb-16mm { padding-bottom: var(--16mm); }
.sheet.printer-mb-17mm { padding-bottom: var(--17mm); }
.sheet.printer-mb-18mm { padding-bottom: var(--18mm); }
.sheet.printer-mb-20mm { padding-bottom: var(--20mm); }
.sheet.printer-mb-25mm { padding-bottom: var(--25mm); }

.sheet.printer-ml-0mm  { padding-left: var(--0mm); }
.sheet.printer-ml-5mm  { padding-left: var(--5mm); }
.sheet.printer-ml-10mm { padding-left: var(--10mm); }
.sheet.printer-ml-15mm { padding-left: var(--15mm); }
.sheet.printer-ml-16mm { padding-left: var(--16mm); }
.sheet.printer-ml-17mm { padding-left: var(--17mm); }
.sheet.printer-ml-18mm { padding-left: var(--18mm); }
.sheet.printer-ml-20mm { padding-left: var(--20mm); }
.sheet.printer-ml-25mm { padding-left: var(--25mm); }

.sheet.printer-mr-0mm  { padding-right: var(--0mm); }
.sheet.printer-mr-5mm  { padding-right: var(--5mm); }
.sheet.printer-mr-10mm { padding-right: var(--10mm); }
.sheet.printer-mr-15mm { padding-right: var(--15mm); }
.sheet.printer-mr-16mm { padding-right: var(--16mm); }
.sheet.printer-mr-17mm { padding-right: var(--17mm); }
.sheet.printer-mr-18mm { padding-right: var(--18mm); }
.sheet.printer-mr-20mm { padding-right: var(--20mm); }
.sheet.printer-mr-25mm { padding-right: var(--25mm); }

/** Print preview **/
@media screen and (prefers-color-scheme: light) {
  body { background: #e0e0e0 }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm auto;
  }
}

@media screen and (prefers-color-scheme: dark) {
  body { background: #282828 }
  .sheet {
    background: #2f2f2f;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.5);
    margin: 5mm auto;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  main.A3.landscape     { width: var(--A3-long) }
  main.A3               { width: var(--A3-short)}
  main.A4.landscape     { width: var(--A4-long) }
  main.A4               { width: var(--A4-short) }
  main.A5.landscape     { width: var(--A5-long) }
  main.A5               { width: var(--A5-short) }
  main.Letter           { width: var(--letter-short) }
  main.Legal            { width: var(--legal-short) }
  main.Letter.landscape { width: var(--letter-long) }
  main.Legal.landscape  { width: var(--legal-long) }
}
