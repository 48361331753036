@mixin marginNote {
  &.has-margin-note {
    margin-top: -0.5rem;
  }

  & span.marginnote-container {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & span.marginnote-container > label.margin-toggle:not(.sidenote-number) {
    display: inline;
  }

  & span.marginnote-container > .sidenote,
  & span.marginnote-container > .marginnote {
    display: none;
  }

  & span.marginnote-container > .margin-toggle:checked + .sidenote,
  & span.marginnote-container > .margin-toggle:checked + .marginnote {
    display: block;
    float: none;
    left: 1rem;
    clear: none;
    width: 95%;
    margin: 0 0 1rem 0;
    vertical-align: baseline;
  }

  & span.marginnote-container > label {
    cursor: pointer;
  }
}
