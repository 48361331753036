h1.title {
  font-family: 'Hoefler Text', serif;
  position: absolute;
  top: 4.4rem;
  font-size: 5rem;
  font-weight: lighter;
}

p.subtitle {
  margin-top: 2.5rem;
}
